import {IReg, REG} from 'lib/commons/registry';
import {BaseElement, OSkinableInit} from "back/commons/basis";
import {initLoginForm, initRenewPwdForm} from "back/core/userSelfForms";
import {JSX} from "lib/commons/xml/dom";
import {DOMSH} from "lib/commons/xml/domsh";
import {IBasicUniversePointer, IUniverseEnv} from "lib/core/universe";
import {Action} from "lib/commons/actions";
import {POPUP} from "back/commons/widgets/popups";
import {UiLangDeskFeat} from "back/core/plugins/optionsPlg";
import {ActionBtn, OActionBtnInit} from "back/commons/widgets/buttons";

export class LoginDialog extends BaseElement {

	protected _initialize(init: OSkinableInit) {
		const reg = this.findReg<IBasicUniversePointer>(init);
		const sr = this.attachShadow(DOMSH.SHADOWDOM_INIT);
		reg.installSkin("webzone:panel", sr);
		this._initAndInstallSkin(this.localName, init);
		const loginForm = reg.getSvc<(reg: IReg<IUniverseEnv>) => HTMLFormElement>('userLoginForm')(reg);
		sr.appendChild(loginForm);
		// Hack pour faire fonctionner l'enregistrement des mots de passe sur Chrome : ajout d'un input password caché en dehors du shadow !
		this.appendChild(<form style="display: none"><input name="password" type="password"/></form>);
		const universe = reg.env.universe;
		initLoginForm(loginForm, universe.userSelf, universe.auth, {
			defaultAccount: localStorage.getItem("login:lastAccount"),
			showRenewPwd: () => {
				const renewForm = reg.getSvc<(reg: IReg<IUniverseEnv>) => HTMLFormElement>('userRenewPwdForm')(reg);
				loginForm.hidden = true;
				sr.appendChild(renewForm);
				return renewForm;
			},
			hideRenewPwd: (renewForm) => {
				renewForm.remove();
				loginForm.hidden = false;
			}
		});
	}

}

REG.reg.registerSkin('c-login', 1, /* language=CSS */ `
	:host {
		display: flex;
		min-height: 0;
		min-width: 0;
		padding: 1em;
	}

	:focus-visible {
		outline: var(--focus-outline);
	}

	.fields {
		max-width: 30em;
		width: 85vw;
		margin: auto;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-row-gap: 0.5em;
		grid-column-gap: 0.5em;
	}

	label {
		text-align: end;
		color: var(--alt1-color);
		user-select: none;
	}

	input {
		background-color: var(--form-bgcolor);
		color: var(--form-color);
		border: 1px solid var(--border-color);
	}

	input:invalid {
		border: 1px solid var(--error-color);
		box-shadow: 0 0 2px var(--error-color);
	}

	.message {
		margin: 1em;
	}

	.message[data-type='pending'] {
		text-align: end;
		font-style: italic;
	}

	.error {
		color: var(--error-color);
		margin: 1em;
	}

	#langCtn {
		display: flex;
		justify-content: flex-end;
		margin-bottom: .5em;
	}

	.footer {
		display: flex;
		min-height: 0;
		min-width: 0;
		justify-content: flex-end;
		margin-block: 1em 0;
		margin-inline: 0;
	}

	.spacer {
		flex: 1;
	}

	button {
		padding: 0.5em;
		border-radius: 5px;
		border: 1px solid var(--border-color);
		background-color: var(--bgcolor);
		color: var(--color);
		cursor: pointer;
		margin-block: 0;
		margin-inline: 1em 0;
	}

	button:hover {
		background-color: var(--pressed-bgcolor);
	}

	button[type=submit] {
		border: none;
		color: var(--inv-color);
		background-color: var(--inv-bgcolor);
	}

	button[type=submit]:hover {
		background-image: linear-gradient(var(--inv-pressed-bgcolor), var(--inv-pressed-bgcolor));
	}
`);

customElements.define('c-login', LoginDialog);

/**
 *
 */
export class ChangePwdDialog extends BaseElement {

	protected _initialize(init: OSkinableInit) {
		const reg = this.findReg<IBasicUniversePointer>(init);
		const sr = this.attachShadow(DOMSH.SHADOWDOM_INIT);
		reg.installSkin("webzone:panel", sr);
		this._initAndInstallSkin('c-login', init);
		const changePwdForm = reg.getSvc<(reg: IReg<IUniverseEnv>) => HTMLFormElement>('userChangePwdForm')(reg);
		sr.appendChild(changePwdForm);
		const universe = reg.env.universe;
		initRenewPwdForm(changePwdForm, universe.userSelf, universe.auth, {
			renewPwdDone: () => {
				POPUP.findPopupableParent(this).close();
			}
		});
	}
}

customElements.define('c-change-pwd', ChangePwdDialog);

REG.reg.registerSvc('userLoginForm', 1, (reg: IReg<IUniverseEnv>) => <form id="loginForm" method="post">
	<section>
		<p class="message" hidden=""/>
		<p class="error" hidden=""/>
		{langSelector(reg)}
		<section class="fields">
			<label for="loginAccount">Account</label>
			<input name="account" id="loginAccount" autocomplete="username" spellcheck="false"/>
			<label for="loginPassword">Password</label>
			<input name="password" type="password" id="loginPassword" autocomplete="current-password"/>
		</section>
		<div class="footer" id="loginActions">
			<button class="pwdLost" type="button">Lost password</button>
			<div class="spacer"/>
			<button type="submit">Connect</button>
		</div>
	</section>
</form>);

REG.reg.registerSvc('userRenewPwdForm', 1, (reg: IReg<IUniverseEnv>) => <form id="renewPwdForm" method="post">
	<section>
		<h3>Renew your password</h3>
		<p class="message" hidden=""/>
		<p class="error" hidden=""/>
		{langSelector(reg)}
		<section class="fields">
			<label for="renewAccount">Account</label>
			<input name="account" id="renewAccount" autocomplete="username" spellcheck="false"/>
			<label for="currentPwd">Current password</label>
			<input name="currentPwd" type="password" id="currentPwd" autocomplete="current-password"/>
			<label for="newPwd">New password</label>
			<input name="newPwd" type="password" id="newPwd" autocomplete="new-password"/>
			<label for="confirmPwd">Confirm password</label>
			<input name="confirmPwd" type="password" id="confirmPwd" autocomplete="new-password"/>
		</section>
		<div class="footer" id="renewPwdActions">
			<button type="submit">Validate</button>
		</div>
	</section>
</form>);

REG.reg.registerSvc('userChangePwdForm', 1, (reg: IReg<IUniverseEnv>) => <form id="changePwdForm" method="post">
	<section>
		<p class="message" hidden=""/>
		<p class="error" hidden=""/>
		{langSelector(reg)}
		<section class="fields">
			<label for="renewAccount">Account</label>
			<input name="account" id="renewAccount" autocomplete="username" disabled="" spellcheck="false"/>
			<label for="currentPwd">Current password</label>
			<input name="currentPwd" type="password" id="currentPwd" autocomplete="current-password"/>
			<label for="newPwd">New password</label>
			<input name="newPwd" type="password" id="newPwd" autocomplete="new-password"/>
			<label for="confirmPwd">Confirm password</label>
			<input name="confirmPwd" type="password" id="confirmPwd" autocomplete="new-password"/>
		</section>
		<div class="footer" id="changePwdActions">
			<button type="submit">Validate</button>
		</div>
	</section>
</form>);

const actionChangePwd = new Action<HTMLElement>("actionChangePwd")
	.setLabel("Change password...")
	.setVisible(function (ctx: HTMLElement) {
		const reg = REG.findReg<IBasicUniversePointer>(ctx);
		return reg.env.universe.auth.currentAuthenticatedUser !== null;
	})
	.setEnabled(function (ctx: HTMLElement) {
		const reg = REG.findReg<IBasicUniversePointer>(ctx);
		let currentUser = reg.env.universe.auth.currentAuthenticatedUser;
		if (currentUser && (currentUser.isReadOnly || currentUser.isDisabled))
			return false;
	})
	.setExecute(function (ctx: HTMLElement, event: Event) {
		POPUP.showDialog(new ChangePwdDialog(), ctx, {fixSize: false, titleBar: {barLabel: {label: "Change your password"}}});
	});

REG.reg.registerSvc('actionChangePwd', 1, actionChangePwd);

function langSelector(reg: IReg<IUniverseEnv>): HTMLElement | undefined {
	if (!window.desk) return;
	if (UiLangDeskFeat.isIn(desk)) {
		return <div id="langCtn"><ActionBtn id="langBtn" î={{
			reg,
			action: desk.newLangMenu(),
			uiContext: "bar"
		} as OActionBtnInit<HTMLElement>}/></div>;
	}
}
